<template>
    <div class="OrderTable-container">
        <h1>
            <!-- VIP 订单 -->
            {{ $t('myProject.vipOrder') }}
        </h1>
        <table>
            <thead>
            <tr>
                <td>
                    <!-- 订单编号 -->
                    {{ $t('myProject.orderSerialNumber') }}
                </td>
                <!--
                <td>付款方银行ID</td>
                -->
                <td>
                    <!-- VIP交费时间 -->
                    {{ $t('myProject.vipPaymentTime') }}
                </td>
                <td>
                    <!-- 交费金额($) -->
                    {{ $t('myProject.paymentAmount') }}
                </td>
                <td>
                    <!-- 支付状态 -->
                    {{ $t('myProject.paymentStatus') }}
                </td>
                <td>
                    <!-- 评估结果 -->
                    {{ $t('myProject.assessmentResults') }}
                </td>
                <td>
                    <!-- VIP 开始日期 -->
                    {{ $t('myProject.vipStartDate') }}
                </td>
                <td>
                    <!-- VIP 到期日 -->
                    {{ $t('myProject.vipExpirationDate') }}
                </td>
                <td>
                    <!-- 售后 -->
                    {{ $t('myProject.afterSales') }}
                </td>
            </tr>
            </thead>
            <tbody v-if="datalist">
            <tr :key="index" v-for="(item, index) in datalist">
                <td>{{ item.order_number }}</td>
                <!--<td>{{ item.pay_account }}</td>-->
                <td>{{ item.create_time }}</td>
                <td>{{ item.payment_amount }}</td>
                <td>{{ item.payment_state_name }}</td>
                <td>{{ item.evaluation_state_name }}</td>
                <td>{{ item.start_time }}</td>
                <td>{{ item.over_time }}</td>
                <td>
                    <div class="post-sale-btn cur ban" v-if="item.after_sale_state == 3">
                        {{ item.after_sale_state_name }}
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <div style="display: flex;justify-content: center;align-items: center;height: 150px;" v-if="!datalist">
      <span>
        <!-- 无订单 -->
        {{ $t('myProject.noOrder') }}
      </span>
        </div>
        <el-pagination :current-page.sync="pageNum" :page-size="pageSize" :total="total"
                       @current-change="handleCurrentChange" layout="prev, pager, next,slot" style="text-align: end">
            <slot>
                <div class="jump-page">
          <span>
            <!-- 前往 -->
            {{ $t('myProject.goTo') }}
          </span>
                    <input type="text" v-model.number="goPageNum"/>
                    <i @click="goJumpPage(goPageNum)" class="el-icon-arrow-right"></i>
                </div>
            </slot>
        </el-pagination>
    </div>
</template>

<script>
    import {buyVipService} from "@/api/myProjectApi";
    import {pageMixin} from "@/mixins"

    export default {
        name: "OrderTable",
        mixins: [pageMixin],

        data() {
            return {
                a: '',
                datalist: [],
            }
        },
        components: {},
        //props: ["datalist",],
        props: ["projectId", "sourceType"],

        mounted() {
            this.getBuyVipService();
        },

        methods: {
            handleCurrentChange: function handleCurrentChange(p) {
                this.pageNum = p;
                this.getBuyVipService();
            },
            goJumpPage(num) {
                this.pageNum = num;
                this.getBuyVipService();
            },
            // 获取vip订单接口
            async getBuyVipService() {
                let res = await buyVipService({
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    projectId: this.projectId,
                    sourceType: this.sourceType,
                    token: localStorage.getItem("token"),
                }).then((res) => {
                    if (res.code == 200) {
                        this.datalist = res.data.list;
                        this.total = res.data.total;
                    }/*else {
          this.$message({message: res.msg, type: "error"});
        }*/
                });
            },

            //VIP订单
            getVIPList() {
                let formData = {
                    pageNum: this.VIPpageNum,
                    pageSize: 10,
                    projectId: this.projectId,
                    sourceType: 1,
                    token: localStorage.getItem("token"),
                };
                buyVipService(formData).then((res) => {
                    if (res.data) {
                        this.datalist = res.data.list;
                    }
                });
            },
        },
    };
</script>

<style scoped>
    h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    table {
        width: 1200px;
        margin-top: 26px;
    }

    table > thead > tr {
        height: 47px;
        background: #fafafa;
    }

    table > thead > tr > td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        text-align: center;
        vertical-align: middle;
    }

    table > tbody > tr {
        height: 54px;
    }

    table > tbody > tr > td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
        text-align: center;
        vertical-align: middle;
    }

    table > tbody > tr > td:last-child {
        width: 233px;
    }

    .post-sale-btn {
        /*width: 99px;*/
        height: 32px;
        background: #ffffff;
        border: 1px solid #707070;
        border-radius: 131px;
        text-align: center;
        line-height: 32px;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin: 0 auto;
    }

    .ban {
        background: #fafafa;
        border: none;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
    }
</style>
