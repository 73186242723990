<template>
    <div :class="type" class="container-button">
        <slot>按钮</slot>
    </div>
</template>

<script>
    export default {
        name: "CustomButton",
        props: {
            type: {
                type: String,
                default: 'black'
            }
        },
    }
</script>

<style scoped>
    .container-button {
        box-sizing: border-box;
        width: 162px;
        height: 50px;
        border-radius: 131px;
        line-height: 50px;
        font-size: 14px;
        font-family: BentonSans;
        font-weight: 500;
        text-align: center;
        cursor: pointer;

    }

    .black {
        background: url("../../../assets/images/btnbg@2x.png");
        background-size: 100% 100%;
        color: #FFFFFF;

    }

    .white {
        border: 1px solid #F1F1F1;
        border-radius: 25px;
        color: #000000;
    }

    .white-line {
        border: 1px solid #707070;
        border-radius: 25px;
        color: #000000;
    }

    .ban {
        background: #FAFAFA;
        border-radius: 25px;
        color: #949494;
        cursor: default;
    }

</style>
