<template>
    <div class="index-container">
        <BreadcrumbNav :page-name="$t('myInterview.myInterviewDetail')"/>
        <div class="main">
            <div class="text-group2">
                <h1 class="over2">{{ details_data.project_name }}</h1>
                <div class="text-group2-content">
                    <div class="publish-date over2" style="width: 60%">{{ details_data.project_intro }}</div>
                </div>
                <!-- 编辑按钮 -->
                <div @click="$router.push({path:'/project/editProject/'+projectId, query:{isInterview:true,}})"
                     class="see-detail-btn"
                     style="right: 300px;border-color: dodgerblue;color: dodgerblue">
                    {{ $t('myProject.edit') }}
                </div>
                <!-- 删除按钮 -->
                <div @click="delProjectById" class="see-detail-btn" style="right: 150px;border-color: red;color: red">
                    {{ $t('myProject.delete') }}
                </div>
                <!-- 查看详情 -->
                <div @click="$router.push({ path:'/project/details/'+details_data.project_id,
                        query:{ type:2, projectCategory: details_data.project_category}})" class="see-detail-btn">
                    {{ $t('myProject.detail') }}
                </div>
            </div>
            <div class="people-wrap">
                <div class="people-info fl">
                    <img :src="details_data.avatar" alt="" class="head-icon fl"/>
                    <div class="people-info-text fl">
                        <h1 class="over">{{ details_data.initiator_name }}</h1>
                        <h2 class="over">{{$t('myProject.vipExpireTime')}}<!--VIP到期时间--> {{ details_data.vip_end_date
                            }}</h2>
                    </div>
                </div>
                <div class="btn-group fl" style="display: flex">
                    <div>
                        <CustomButton
                                @click.native="$router.push('/VIP?type=' + details_data.project_genre+'&&projectId='+delivery_data.id)"
                                class="fl" type="black">
                            {{ $t("myInterviewDetail.vipBuy") }}
                        </CustomButton>
                    </div>
                    <!-- <div>v-if="details_data.openHide == '9'"隐藏公开发布按钮
                      <CustomButton v-if="details_data.isOpen=='0'" class="fl" @click.native="get_release" type="black" style="margin-left: 26px">
                        {{ $t("myInterviewDetail.publicRelease") }}
                      </CustomButton>
                      <CustomButton v-if="details_data.isOpen=='1'" class="fl" type="ban" style="margin-left: 26px">
                        {{ $t("myInterviewDetail.alreadyPublicRelease") }}
                      </CustomButton>
                    </div>-->
                </div>
            </div>
            <!--VIP订单-->
            <OrderTable :project-id="this.$route.query.id" :sourceType="2" style="margin-top: 27px"/>
            <hr/>
            <!--VIP私密推荐、访谈详情-->
            <RecommendTable ref="recommendTable" style="margin-top: 65px" v-on:pop_Button="pop_Button"/>
            <hr/>
            <div>
                <CustomButton @click.native="publishMulti" style="margin-top: 30px" type="black" v-if="isDetail">
                    {{ $t("myInterviewDetail.multiPersonCommunication") }}
                </CustomButton>
            </div>
            <!--多人邀约记录-->
            <MorePersonInviteTable :project-id="this.$route.query.id" style="margin-top: 65px" v-if="isDetail"/>
        </div>
        <el-dialog :title="$t('myInterviewDetail.manyPeopleOffer')" :visible.sync="dialogFormVisible">
            <el-form :model="form">
                <el-form-item :label="$t('myInterviewDetail.invitationBecause')" :label-width="formLabelWidth">
                    <el-input autocomplete="off" v-model="form.cause"></el-input>
                </el-form-item>
                <el-form-item :label="$t('myInterviewDetail.phone')" :label-width="formLabelWidth">
                    <el-input autocomplete="off" v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item :label="$t('myInterviewDetail.email')" :label-width="formLabelWidth">
                    <el-input autocomplete="off" v-model="form.mail"></el-input>
                </el-form-item>
                <el-form-item :label="$t('myInterviewDetail.invitationTime')" :label-width="formLabelWidth">
                    <div class="block">
                        <el-date-picker :placeholder="$t('myInterviewDetail.optionDate')" type="date"
                                        v-model="time_value1"
                                        value-format="yyyy-MM-DD">
                        </el-date-picker>
                        <el-time-select :picker-options="{ start: '08:30',step: '00:15',end: '18:30',}"
                                        :placeholder="$t('myInterviewDetail.selectTime')"
                                        v-model="time_value2"
                                        value-format="HH:mm:ss">
                        </el-time-select>
                    </div>
                </el-form-item>
            </el-form>
            <div class="dialog-footer" slot="footer">
                <el-button @click="dialogFormVisible = false">{{ $t("myInterviewDetail.cancel") }}</el-button>
                <el-button @click="people_communicate" type="primary">{{ $t("myInterviewDetail.determine") }}
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import CustomButton from "@/components/my_project/vip_private_recommendation/CustomButton";
    import OrderTable from "@/components/my_project/OrderTable";
    import RecommendTable from "@/components/my_project/my_interview/RecommendTable";
    import MorePersonInviteTable from "@/components/my_project/MorePersonInviteTable";
    import CancelAppointment from "@/components/dialog/CancelAppointment";
    import {
        myInterviewListDetails,
        publicRelease,
        communicate, delProjectById,
    } from "@/api/myProjectApi";

    export default {
        name: "index",
        data() {
            return {
                projectId: "",
                isDetail: false,
                delivery_data: {},
                dialogFormVisible: false,
                form: {
                    cause: "",
                    mail: "",
                    phone: "",
                },
                formLabelWidth: "120px",
                details_data: {},
                pop_data: [],
                pop_list: [],
                time_value1: "",
                time_value2: "",
            };
        },
        components: {
            BreadcrumbNav,
            CustomButton,
            OrderTable,
            RecommendTable,
            MorePersonInviteTable,
            CancelAppointment,
        },
        props: {},
        mounted() {
            this.projectId = this.$route.query.id;
            this.delivery_data = this.$route.query;
            this.get_details();
        },
        methods: {
            // 组件传值
            pop_Button(key) {
                this.pop_data = key;
            },
            changeChildTab(index) {
                this.isDetail = index === 0;
            },
            //发起多人沟通
            publishMulti() {
                let ids = this.$refs.recommendTable.checkModel;
                if (ids.length > 1) {
                    this.$router.push({
                        path: '/myInterview/publishMulti',
                        query: {
                            ids: ids,
                            projectId: this.delivery_data.id,
                        }
                    });
                } else {
                    this.$message.warning('Select at least two people')//至少选择一个
                }
            },
            //   获取我发起的 详情数据
            async get_details() {
                let formData = {
                    token: localStorage.getItem("token"),
                    projectId: this.delivery_data.id,
                };
                let res = await myInterviewListDetails(formData);
                if (res.code == 200) {
                    this.details_data = res.data;
                } else {
                    this.$message.error(res.msg)
                }
            },
            // 公开发布
            async get_release() {
                let res = await publicRelease({
                    token: localStorage.getItem("token"),
                    projectId: this.delivery_data.id,
                });
                if (res.code == 200) {
                    await this.get_details();
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                }
            },

            // 发起多人邀请
            async people_communicate() {
                let formData = {
                    token: localStorage.getItem("token"),
                    projectId: this.delivery_data.id,
                    inviteeSource: 1,
                    inviteType: 2,
                };
                if (this.pop_data.length > 0) {
                    this.pop_data.forEach((item, index) => {
                        if (index > 0) {
                            formData.inviteeIds = formData.inviteeIds + "," + item;
                        } else {
                            formData.inviteeIds = item;
                        }
                    });
                    formData.invitationTime = this.time_value1 + " " + this.time_value2;
                    formData.invitationReason = this.form.cause;
                    formData.mobileNumber = this.form.phone;
                    formData.mailBox = this.form.mail;
                    let res = await communicate(formData);
                    if (res.code == 200) {
                        this.$message({
                            message: "发起成功",
                            type: "success",
                        });
                    }
                } else {
                    this.$message({
                        message: "请先选择推荐人",
                        type: "warning",
                    });
                }
            },
            //  项目删除
            delProjectById() {
                let formData = {
                    token: localStorage.getItem("token"),
                    projectType: 2,
                    projectId: this.projectId,
                };
                this.$confirm(this.$t('myProject.confirmDel'), this.$t('publish.tips'), {
                    confirmButtonText: this.$t('publish.determine'),
                    cancelButtonText: this.$t('publish.cancel'),
                    type: 'warning'
                }).then(() => {
                    return delProjectById(formData).then((res) => {
                        if (res.code == 200) {
                            this.$message.success(res.msg)
                            this.$router.go(-1)
                        } else {
                            this.$message.error(res.msg)
                        }
                    });
                });

            },
        },
    };
</script>

<style scoped>
    .index-container {
        background-color: #ffffff;
    }

    .main {
        width: 1200px;
        padding-top: 14px;
        padding-bottom: 24px;
        margin: 0 auto;
    }

    .text-group2 {
        box-sizing: border-box;
        width: 100%;
        min-height: 120px;
        border-bottom: 1px solid #e5e5e5;
        padding-top: 30px;
        position: relative;
    }

    .see-detail-btn {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 25px;
        width: 124px;
        height: 40px;
        border: 1px solid #707070;
        border-radius: 131px;
        text-align: center;
        line-height: 40px;
    }

    .text-group2-content {
        margin-top: 12px;
    }

    .publish-date {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        line-height: 1.2;
    }

    .brief-intro {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-left: 16px;
    }

    .text-group2 > h1 {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        width: 60%;
        line-height: 1.2;
    }

    .people-wrap {
        padding: 38px 0;
        overflow: hidden;
    }

    .people-wrap .head-icon {
        width: 67px;
        height: 67px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-info-text {
        margin-left: 13px;
        min-width: 160px;
        max-width: 300px;
    }

    .people-info-text > h1 {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-top: 8px;
    }

    .people-info-text > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        margin-top: 13px;
        /* margin-left: 4px; */
    }

    .people-wrap .btn-group {
        margin-top: 4px;
        margin-left: 136px;
    }

    hr {
        background-color: #e5e5e5;
    }
</style>
