<template>
    <div class="RecommendTable-container">
        <div class="tabs">
            <ul>
                <li :class="{ tabClass: isTab === 0 }" @click="tabClick(0)">{{ $t("myInterviewDetail.private") }}</li>
                <li :class="{ tabClass: isTab === 1 }" @click="tabClick(1)">{{ $t("myInterviewDetail.interviewDetails")
                    }}
                </li>
            </ul>
        </div>
        <!--VIp私密推荐tab-->
        <div class="table1" v-if="isTab === 0">
            <table>
                <thead>
                <tr>
                    <td>
                        <label>
                            <input @change="checkAll" type="checkbox" v-model="checked"/>{{
                            $t("myInterviewDetail.selectAll") }}
                        </label>
                    </td>
                    <td>{{ $t("myInterviewDetail.referenceInformation") }}</td>
                    <td>{{ $t("myInterviewDetail.reasonsForRecommendation") }}</td>
                    <td>{{ $t("myInterviewDetail.interviewTime") }}</td>
                    <td>{{ $t("myInterviewDetail.operation") }}</td>
                    <td>{{ $t("myInterviewDetail.speedOfProgress") }}</td>
                    <td>{{ $t("myInterviewDetail.startTime") }}</td>
                </tr>
                </thead>
                <tbody>
                <tr :key="index" v-for="(item, index) in vip_list">
                    <td>
                        <label><input :value="item.invitee_id" type="checkbox" v-model="checkModel"/></label>
                    </td>
                    <td>
                        <div class="recommend-people-info">
                            <!-- <img class="rpi-head" src="@/assets/images/1.png" alt="" /> -->
                            <img :src="item.invitee_image" alt="" class="rpi-head"/>
                            <div class="rpi-text">
                                <div class="rpi-name over">{{ item.invitee_name }}</div>
                                <div class="rpi-position over">
                                    {{ item.company_name }} · {{ item.position }}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="r-limit">
                            <div @click="get_introduceTemplate(item)" class="r-reason-model" style="cursor: pointer"
                                 v-if="item.reason_show == 1">
                                <!-- 推荐原因 -->
                                {{ $t('myProject.recommendedTemplate') }}
                            </div>
                            <div style="margin-left: 38px" v-else>-</div>
                            <!--<div class="r-reason">{{ $t("myInterviewDetail.recommendMail") }} {{ $t("myInterviewDetail.reasonsForRecommendation") }}</div>
                            <div class="r-reason-model">{{ item.introduce_reason }}</div>-->
                        </div>
                    </td>
                    <td>
                        <div class="interview-time" v-if="item.invitation_time">
                            {{ item.invitation_time }}
                        </div>
                        <div class="no-op" v-else>-</div>
                    </td>
                    <td>
                        <div class="operation">
                            <div class="btn-op" v-if="item.progress=='3'">
                                <div @click="freeTalk(item.interview_id)" class="btn-op-item cur ">
                                    {{ $t("myInterviewDetail.willingToCommunicateForFree") }}
                                </div>
                                <div @click="refuseTalk(item.interview_id)" class="btn-op-item ban  cur">
                                    {{ $t("myInterviewDetail.refuse") }}
                                </div>
                            </div>

                            <div class="operation" v-if="item.progress=='7'">
                                <div @click="btnCancel(item)" class="btn-op-item cur" style="margin: 0 auto"
                                     v-if="item.isShow == 1">
                                    {{ $t("myInterviewDetail.cancelReservation") }}
                                </div>
                                <div @click="$router.push({path:'/videoRoom', query:{interviewId:item.interview_id, type:2, isPublisher:1,}})"
                                     class="btn-op-item cur" style="margin: 10px auto"
                                     v-if="item.isFanTan == 1">
                                    {{ $t("myInterviewDetail.interview") }}
                                </div>
                            </div>

                            <div class="operation" v-if="item.progress=='10'&&item.is_evaluate=='0'">
                                <div @click="evaluate(item)" class="btn-op-item cur" style="margin: 0 auto">
                                    {{ $t("myInterviewDetail.immediateEvaluation") }}
                                </div>
                            </div>

                            <!--<div class="operation" v-else>
                              <div class="no-op">-</div>
                            </div>-->
                        </div>

                    </td>
                    <td>
                        <div class="two-span">
                            <p>{{ item.progress_name }}</p>
                            <!-- <p>受邀时间冲突</p> -->
                        </div>
                    </td>
                    <td>
                        <div class="no-op" v-if="item.initiator_feedback_time">{{ item.initiator_feedback_time }}</div>
                        <div class="no-op" v-else>-</div>
                        <!--<div v-if="item.invitation_time" class="no-op">{{ item.create_time }}</div>
                        <div v-else class="no-op">-</div>-->
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <!--访谈详情tab-->
        <div class="table2" v-else>
            <table>
                <thead>
                <tr>
                    <td>{{ $t("myInterviewDetail.invitee") }}</td>
                    <td>{{ $t("myInterviewDetail.orderSerialNumber") }}</td>
                    <td>{{ $t("myInterviewDetail.orderTime") }}</td>
                    <td>{{ $t("myInterviewDetail.speedOfProgress") }}</td>
                    <td>{{ $t("myProject.price") }}</td>
                    <td>{{ $t("myInterviewDetail.paymentStatus") }}</td>
                    <td>{{ $t("myInterviewDetail.operation") }}</td>
                </tr>
                </thead>
                <tbody>
                <tr :key="index" v-for="(item, index) in inter_list">
                    <td>
                        <div class="recommend-people-info">
                            <img :src="item.avatar" alt="" class="rpi-head"/>
                            <!-- <img class="rpi-head" src="@/assets/images/1.png" alt="" /> -->

                            <div class="rpi-text">
                                <div class="rpi-name over">{{ item.invitee_name }}</div>
                                <div class="rpi-position over">
                                    {{ item.company_name }} · {{ item.position }}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>{{ item.order_number }}</td>
                    <td>{{ item.create_time }}</td>
                    <td>
                        <div class="two-span">
                            <p>{{ item.progress_name }}</p>
                            <div class="interview-time1" v-if="item.progress==3 && item.invitation_time">
                                <div class="key">{{ $t("myInterviewDetail.interviewTime") }}</div>
                                <div class="value">{{ item.invitation_time }}</div>
                            </div>
                        </div>
                    </td>
                    <td>${{ item.exchange_price_video }}</td>
                    <td>
                        <span>{{ item.payment_state_name }}</span>
                        <div :title="item.after_sale_fail_reason"
                             style="width: 110px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                            {{ item.after_sale_fail_reason }}
                        </div>
                    </td>
                    <td>
                        <div class="operation" style="padding: 0 60px;" v-if="item.progress==3">
                            <div @click="btnCancel2" class="btn-op-item cur" style="margin: 0 auto; width: 150px;"
                                 v-if="item.whether == 2">
                                {{ $t("myInterviewDetail.cancelReservation") }}
                            </div>
                            <div @click="$router.push({path:'/videoRoom', query:{interviewId:item.interview_id, type:3, isPublisher:1,}})"
                                 class="btn-op-item cur" style="margin: 0 auto"
                                 v-if="item.whether == 1">
                                {{ $t("myInterviewDetail.interview") }}
                            </div>
                        </div>
                        <div class="operation" v-else-if="item.progress==6&&item.is_evaluate==0">
                            <div class="btn-op">
                                <div @click="$refs.applyRefundDialog.openDialog(item.order_id)" class="btn-op-item cur"
                                     v-if="item.payment_state!=1&&item.payment_state==2">
                                    {{ $t("myWallet.applyForARefund") }}
                                </div>
                                <div class="btn-op-item ban" v-else-if="item.payment_state!=1&&item.payment_state==3">
                                    {{ $t("myInterviewDetail.refundHasBeenAppliedFor") }}
                                </div>
                                <div class="btn-op-item ban" v-else-if="item.payment_state!=1&&item.payment_state==4">
                                    {{ $t("myInterviewDetail.successfullyApplyForRefund") }}
                                </div>
                                <div class="btn-op-item ban" v-else-if="item.payment_state!=1">
                                    <p>{{ $t("myInterviewDetail.failedToApplyForRefund") }}</p>
                                    <p>{{ item.after_sale_fail_reason }}</p>
                                </div>
                                <div @click="evaluate2(item)" class="btn-op-item ban cur">{{
                                    $t("myInterviewDetail.immediateEvaluation") }}
                                </div>
                            </div>
                        </div>
                        <div class="operation" v-else-if="item.progress==6&&item.is_evaluate==1">
                            <el-popover :content.sync="item.evaluation_content_sponsor" placement="top-start"
                                        trigger="hover" width="200">
                                <el-button slot="reference">{{ $t("myInterviewDetail.haveEvaluation") }}</el-button>
                            </el-popover>
                        </div>
                        <div class="no-op" v-else>-</div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <el-pagination
                :current-page.sync="pageNum"
                :page-size="pageSize"
                :total="total"
                @current-change="handleCurrentChange"
                layout="prev, pager, next,slot"
                style="text-align: end">
            <slot>
                <div class="jump-page">
                    <span>{{ $t("myInterviewDetail.go") }}</span>
                    <input type="text" v-model.number="goPageNum"/>
                    <i @click="goJumpPage(goPageNum)" class="el-icon-arrow-right"></i>
                </div>
            </slot>
        </el-pagination>
        <ApplyRefundDialog ref="applyRefundDialog" vipDetails="vip"/>
        <CancelAppointment ref="cancelAppointmentDialog"/>
        <CancelAppointment2 ref="cancelAppointment2Dialog"/>
        <EvaluateDialog ref="evaluateDialog"/>
        <Evaluate2Dialog ref="evaluate2Dialog"/>
    </div>
</template>

<script>
    import {
        inviteDetailsForPrivacy,
        interviewList,
        initiatorFeedback,
        publicRelease,
        myInterviewListDetails
    } from "@/api/myProjectApi";
    import {vipPrivateRecommended} from "@/api/commonApi";
    import {pageMixin} from "@/mixins";
    import CancelAppointment from "@/components/dialog/CancelAppointment";
    import EvaluateDialog from "@/components/dialog/EvaluateDialog";
    import Evaluate2Dialog from "@/components/dialog/Evaluate2Dialog";
    import CancelAppointment2 from "@/components/dialog/CancelAppointment2";
    import {
        setPublisherCancelInvitation,
        publisherImmediateEvaluation,
        publisherCancelInvitation
    } from "@/api/myProjectApi/interviewApi";
    import {initiatorEvaluatel} from "@/api/projectAbout"
    import ApplyRefundDialog from "@/components/dialog/ApplyRefundDialog";

    export default {
        name: "RecommendTable",
        mixins: [pageMixin],
        data() {
            return {
                isTab: 0,
                tabs: ["VIP私密推荐", "访谈详情"],
                list: [],
                vip_list: [],
                inter_list: [],
                checked: false, //是否全选
                checkModel: [], //双向数据绑定的数组，我是用的id
                choice_arry: [],
                interviewId: '',
                details_data: {},
            };
        },
        components: {
            CancelAppointment,
            EvaluateDialog,
            Evaluate2Dialog,
            CancelAppointment2,
            ApplyRefundDialog
        },
        props: {},
        mounted() {
            this.tabClick(0);
            this.get_details();
        },
        methods: {
            btnCancel(item) {
                this.$refs.cancelAppointmentDialog.setInterviewId(item.interview_id);
                this.$refs.cancelAppointmentDialog.openDialog();
                this.interviewId = item.interview_id
            },
            btnCancel2() {
                this.$refs.cancelAppointment2Dialog.openDialog();
            },
            evaluate(item) {
                this.$refs.evaluateDialog.setItem(item);
                this.$refs.evaluateDialog.openDialog();
                this.interviewId = item.interview_id
            },
            evaluate2(item) {
                this.$refs.evaluate2Dialog.setItem(item);
                this.$refs.evaluate2Dialog.openDialog();
            },

            // 发起人评论提交（VIP私密推荐
            async initiatorEvaluate(form) {
                let res = await initiatorEvaluatel({
                    token: localStorage.getItem("token"),
                    interviewId: this.interviewId,
                    mobileNumber: form.phone,
                    mailBox: form.mail,
                    whatsApp: form.whatsApp,
                    content: form.content,
                    rating: form.rating,
                });
                if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                    this.tabClick(0);
                    this.$refs.evaluateDialog.closeDialog();
                } else {
                    this.$message.error(res.msg);
                }
            },
            // 我发起的3分钟访谈-详情页-发起人立即评价（访谈）A~
            async publisherEvaluation(form) {
                let res = await publisherImmediateEvaluation({
                    token: localStorage.getItem("token"),
                    projectId: this.$route.query.id,
                    mobileNumber: form.phone,
                    mailBox: form.mail,
                    whatsApp: form.whatsApp,
                    content: form.content,
                    rating: form.rating,
                });
                if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                    this.tabClick(1);
                    this.$refs.evaluate2Dialog.closeDialog();
                } else {
                    this.$message.error(res.msg);
                }
            },
            // vip取消邀约
            async cancelAppointment(a, form) {
                //let res = await setPublisherCancelInvitation({
                let res = await initiatorFeedback({
                    token: localStorage.getItem("token"),
                    interviewId: form.interviewId,
                    mobileNumber: form.phone,
                    mobileCode: form.mobileCode,
                    mailBox: form.mail,
                    whatsappCode: form.mobileCode,
                    whatsApp: form.whatsApp,
                    rejectReason: form.reason,
                    feedback: '2',//1.同意，2.拒绝
                });
                if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                    this.tabClick(0);
                } else {
                    this.$message.error(res.msg);
                }
            },
            // 取消邀约
            async publisherCancelAppointment(form) {
                let res = await publisherCancelInvitation({
                    token: localStorage.getItem("token"),
                    projectId: this.$route.query.id,
                    mobileNumber: form.phone,
                    mailBox: form.mail,
                    whatsApp: form.whatsApp,
                    cancellationReason: form.reason,
                });
                if (res.code == 200) {
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                    this.tabClick(0);
                } else {
                    this.$message.error(res.msg);
                }
            },
            //免费沟通
            freeTalk(interviewId) {
                this.$router.push({
                    path: '/myInterview/fiveTimeInterview',
                    query: {
                        state: -1,//发起五个时间
                        id: interviewId,
                    }
                });
            },
            //拒绝免费沟通
            refuseTalk(interviewId) {
                this.$refs.cancelAppointmentDialog.setInterviewId(interviewId);
                this.$refs.cancelAppointmentDialog.openDialog(interviewId);
            },

            handleCurrentChange: function handleCurrentChange(p) {
                this.pageNum = p;
                this.getData();
            },
            goJumpPage(num) {
                this.pageNum = num;
                this.getData();
            },
            //根据tab请求数据
            getData() {
                if (this.isTab === 0) {
                    this.get_vipprivate();
                } else {
                    this.get_interview();
                }
            },
            // 访谈详情
            async get_interview() {
                let formData = {
                    projectId: this.$route.query.id,
                    token: localStorage.getItem("token"),
                };
                let res = await interviewList(formData);
                if (res.code == 200) {
                    this.inter_list = [];
                    if (typeof res.data == "object") {
                        this.inter_list.push(res.data);
                    } else {
                        this.inter_list = [];
                    }
                }
            },
            // vip推荐
            async get_vipprivate() {
                let formData = {
                    projectId: this.$route.query.id,
                    token: localStorage.getItem("token"),
                    pageNum: 1,
                    pageSize: 10,
                    sourceType: 2,
                };
                let res = await vipPrivateRecommended(formData);
                if (res.code == 200) {
                    this.vip_list = res.data.list;
                }
            },
            tabClick(index) {
                this.isTab = index;
                this.$parent.changeChildTab(index);
                this.pageNum = 1;
                this.goPageNum = 1;
                this.getData();
            },
            checkAll() {
                if (this.checked) {
                    this.vip_list.forEach((item) => {
                        if (this.checkModel.indexOf(item.invitee_id) === -1) {
                            this.checkModel.push(item.invitee_id);
                        }
                    });
                    console.log("ids:", this.checkModel.toString())
                } else {
                    this.checkModel = [];
                }
            },
            get_introduceTemplate(item) {
                console.log(item)
                this.$router.push({
                    path: "/recommendationReason/" + this.$route.query.id,
                    query: {
                        coverUrl: this.details_data.project_cover_url,
                        title: this.details_data.project_name,
                        content: item.introduce_reason,
                    }
                });
            },
            //   获取我发起的 详情数据
            async get_details() {
                let formData = {
                    token: localStorage.getItem("token"),
                    projectId: this.$route.query.id,
                };
                let res = await myInterviewListDetails(formData);
                if (res.code == 200) {
                    this.details_data = res.data;
                } else {
                    this.$message.error(res.msg)
                }
            },
        },
        watch: {
            checkModel() {
                this.checked = this.checkModel.length === this.vip_list.length;
                this.$emit("pop_Button", this.checkModel);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../../assets/css/element_reset.css";

    h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .tabs > ul > li {
        cursor: pointer;
        display: inline-block;
        margin-right: 55px;

        font-size: 23px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 30px;
        color: #000000;
    }

    .tabClass {
        font-size: 24px !important;
        font-weight: bold !important;
        line-height: 31px !important;
        color: #000000 !important;
    }

    .table1 table {
        width: 1200px;
        margin-top: 26px;
    }

    .table1 table > thead > tr {
        height: 47px;
        background: #fafafa;
    }

    .table1 table > thead > tr > td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        text-align: center;
        vertical-align: middle;
    }

    .table1 table > thead > tr > td:first-child {
        box-sizing: border-box;
        width: 71px;
        text-align: left;
        padding-left: 18px;
    }

    .table1 table > tbody > tr > td:first-child {
        text-align: left;
        padding-left: 18px;
    }

    .table1 table > thead > tr > td:nth-child(2) {
        width: 192px;
    }

    .table1 table > thead > tr > td:nth-child(3) {
        width: 139px;
    }

    .table1 table > tbody > tr > td:nth-child(3) {
        box-sizing: border-box;

        text-align: left;
        padding-left: 26px;
    }

    .table1 table > thead > tr > td:nth-child(4) {
        width: 199px;
    }

    /*.table1 table > thead > tr > td:nth-child(5) {*/

    /*  width: 216px;*/
    /*}*/
    .table1 table tr > td:nth-child(5) {
        padding: 0 20px;

        /*width: 216px;*/
    }

    .table1 table tr > td:nth-child(6) {
        /*padding: 0 20px;*/

        width: 216px;
    }

    .table1 table tr > td:nth-child(7) {
        /*padding: 0 20px;*/

        width: 134px;
    }

    .table1 table > tbody > tr {
        height: 113px;
    }

    .table1 table > tbody > tr > td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
        text-align: center;
        vertical-align: middle;
    }

    .table2 table {
        width: 1200px;
        margin-top: 26px;
    }

    .table2 table > thead > tr {
        height: 47px;
        background: #fafafa;
    }

    .table2 table > thead > tr > td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        text-align: center;
        vertical-align: middle;
    }

    .table2 table > thead > tr > td:first-child {
        width: 178px;
    }

    .table2 table > thead > tr > td:nth-child(2) {
        width: 182px;
    }

    .table2 table > thead > tr > td:nth-child(3) {
        width: 150px;
    }

    .table2 table > thead > tr > td:nth-child(4) {
        width: 176px;
    }

    .table2 table > thead > tr > td:nth-child(5) {
        width: 110px;
    }

    .table2 table > thead > tr > td:nth-child(6) {
        width: 132px;
    }

    .table2 table > tbody > tr {
        height: 113px;
    }

    .table2 table > tbody > tr > td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
        text-align: center;
        vertical-align: middle;
    }

    .rpi-head {
        width: 49px;
        height: 49px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
        vertical-align: middle;
    }

    .rpi-text {
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
    }

    .rpi-name {
        width: 115px;
        font-size: 15px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 17px;
        color: #000000;
    }

    .rpi-position {
        width: 115px;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        margin-top: 6px;
    }

    .r-limit {
        width: 112px;
    }

    .r-reason {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
    }

    .r-reason-model {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        text-decoration: underline;
    }

    .interview-time {
        box-sizing: border-box;
        width: 166px;
        height: 49px;
        background: #f1f1f1;
        border-radius: 131px;
        padding: 5px 37px;

        text-align: center;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 20px;
        color: #000000;
        margin: 0 auto;
    }

    .interview-time1 {
        box-sizing: border-box;
        width: 166px;
        height: 49px;
        background: #f1f1f1;
        border-radius: 131px;
        padding: 5px 0;
        text-align: center;
        margin: 0 auto;
    }

    .interview-time1 .key {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 17px;
        color: #000000;
    }

    .interview-time1 .value {
        font-size: 13px !important;
        font-family: Microsoft YaHei;
        font-weight: bold !important;
        line-height: 17px !important;
        color: #000000 !important;
    }

    .operation {
        text-align: center;
        /*padding-top: 10px;*/
    }

    .btn-op {
        /*width: 216px;*/
        overflow: hidden;
        margin: 0 auto;
    }

    .btn-op-item {
        &:last-child {
            margin-top: 5px;
        }

        box-sizing: border-box;
        padding: 0 8px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #707070;
        border-radius: 131px;

        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .ban {
        border: 1px solid #f1f1f1;
    }

    .no-op {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .two-span {
        text-align: center;
    }

    .two-span p:first-child {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .two-span p:nth-child(2) {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;
        color: #707070;
        margin-top: 6px;
    }

    /* checkbox样式*/
    input[type="checkbox"] {
        /*同样，首先去除浏览器默认样式*/
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /*编辑我们自己的样式*/
        position: relative;
        width: 16px;
        height: 16px;
        bottom: 2px;
        margin-right: 5px;

        background: transparent;
        border: 1px solid #8a8a8a;
        /*border-radius: 4px;*/
        outline: none;
        cursor: pointer;
    }

    input[type="checkbox"] {
        /*同样，首先去除浏览器默认样式*/
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /*编辑我们自己的样式*/
        position: relative;
        background: url("../../../assets/images/radio_n@2x.png") no-repeat;
        background-size: 100% 100%;
        border: 0;
    }

    input[type="checkbox"]:after {
        content: "";
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        text-align: center;
        line-height: 18px;
    }

    input[type="checkbox"]:checked:after {
        border-radius: 0;
        opacity: 1;
    }

    input[type="checkbox"]:checked {
        background: url("../../../assets/images/radio_s@2x.png") no-repeat;
        background-size: 100% 100%;
    }
</style>
