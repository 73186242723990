<template>
    <div class="MorePersonInviteTable-container">
        <h1>{{ $t("myInterviewDetail.multiPersonInvitationRecord") }}</h1>
        <table>
            <thead>
            <tr>
                <td>{{ $t("myInterviewDetail.multiUserCommunicationTime") }}</td>
                <td>{{ $t("myInterviewDetail.progressOfInvitees") }}</td>
                <td>{{ $t("myInterviewDetail.agreed") }}</td>
                <td>{{ $t("myInterviewDetail.waitingForFeedback") }}</td>
                <td>{{ $t("myInterviewDetail.dateOfInvitation") }}</td>
                <td>{{ $t('myProject.operation') }}</td>
            </tr>
            </thead>
            <tbody>
            <tr :key="index" v-for="(item, index) in list" v-show="list.length > 0">
                <td>
                    <p>{{ item.invitation_time | dateYMDHMSFormat2 }}</p>
                    <p>{{ item.invitation_time.substring(10,16) }}</p>
                </td>
                <td>
                    <p>{{ $t("myInterviewDetail.invitationNum") }} {{ item.invite_amount }}</p>
                    <p>{{ $t("myInterviewDetail.agreedNum") }} {{ item.consent_number }}</p>
                </td>
                <td v-if="item.toConsent">
                    <p :key="index2" v-for="(items, index2) in item.toConsent">
                        <span @click="gopage('/user/index', items)">{{ items.invitee }}</span>
                    </p>
                </td>

                <td v-if="item.toFeedback">
                    <p :key="index2" v-for="(items, index2) in item.toFeedback">
                        <span @click="gopage('/user/index', items)">{{ items.invitee }}</span>
                    </p>
                </td>
                <td v-else>
                    <span>-</span>
                </td>
                <td>{{ item.invitation_time | dateYMDHMSFormat2 }}</td>
                <td>
                    <div @click="$router.push({ path:'/videoRoom', query:{ interviewId:item.invite_id, type:4, isPublisher:1, inviteType:2 } })"
                         class="btn-op-item cur" style="margin-left: 10px"
                         v-if="item.consent_number > 0 && item.isFanTan ==1">
                        {{ $t('myProject.interview') }}<!-- 访谈 -->
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <hr>
        <el-pagination :current-page.sync="pageNum" :page-size="pageSize" :total="total"
                       @current-change="handleCurrentChange" layout="prev, pager, next,slot" style="text-align: end">
            <slot>
                <div class="jump-page">
                    <span>{{ $t("myInterviewDetail.go") }}</span>
                    <input type="text" v-model.number="goPageNum"/>
                    <i @click="goJumpPage(goPageNum)" class="el-icon-arrow-right"></i>
                </div>
            </slot>
        </el-pagination>
    </div>
</template>

<script>
    import {multiInviteDetail} from "@/api/myProjectApi";
    import {pageMixin} from "@/mixins";

    export default {
        name: "MorePersonInviteTable",
        mixins: [pageMixin],

        data() {
            return {};
        },
        components: {},
        props: ["projectId"],

        mounted() {
            this.getMultiInviteDetail();
        },
        methods: {
            gopage(url, items) {
                this.$router.push({path: url, query: {userType: 2, talentId: items.invitee_id,}});
            },
            handleCurrentChange: function handleCurrentChange(p) {
                this.pageNum = p;
                this.getMultiInviteDetail();
            },
            goJumpPage(num) {
                this.pageNum = num;
                this.getMultiInviteDetail();
            },
            // 多人沟通记录
            async getMultiInviteDetail() {
                let formData = {
                    projectId: this.projectId,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    token: localStorage.getItem("token"),
                };
                let res = await multiInviteDetail(formData);
                if (res.code == 200) {
                    this.list = res.data.list;
                }
            },
        },
    };
</script>

<style scoped>
    .btn-op-item {
        cursor: pointer;
        box-sizing: border-box;
        width: 99px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #707070;
        border-radius: 131px;

        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    table {
        width: 1200px;
        margin-top: 26px;
    }

    table > thead > tr {
        height: 47px;
        background: #fafafa;
    }

    table > thead > tr > td {
        width: 150px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        text-align: left;
        vertical-align: middle;
    }

    table > thead > tr > td:first-child {
        text-align: center;
        width: 160px;
    }

    table > thead > tr > td:nth-child(2) {
        width: 161px;
    }

    table > thead > tr > td:nth-child(3) {
        width: 370px;
    }

    table > thead > tr > td:nth-child(4) {
        width: 402px;
    }

    table > tbody > tr {
        height: 83px;
    }

    table > tbody > tr > td {
        text-align: left;
        font-family: Microsoft YaHei;
        vertical-align: middle;
    }

    table > tbody > tr > td:first-child {
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        line-height: 20px;
        color: #000000;
    }

    table > tbody > tr > td:nth-child(2) {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
    }

    table > tbody > tr > td:nth-child(3) {
        font-size: 15px;
        font-weight: normal;
        line-height: 24px;
        color: #000000;
    }

    table > tbody > tr > td:nth-child(4) {
        font-size: 15px;
        font-weight: normal;
        line-height: 24px;
        color: #000000;
    }

    table > tbody > tr > td:nth-child(5) {
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }
</style>
